import { useRef, useEffect, useState } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import LoadingIcon from "../../assets/images/loading.svg";
import LoadingWhiteIcon from "../../assets/images/loading-white.svg";
import CircleCheckGreenIcon from "../../assets/images/circle-check-green.svg";
import CircleCheckIcon from "../../assets/images/circle-check.svg";

const PUBLISHABLE_ROBOFLOW_API_KEY = "rf_OcYUOHkfKMVpubqjrQLilqRui6j1";
// const MODEL = "person-faces";
// const MODEL_VERSION = "5";
const MODEL = "face-detection-xiunt";
const MODEL_VERSION = "3";
const API_KEY = "key_b8d10e3d90e6235249bb2";

declare global {
  interface Window {
    roboflow: any;
  }
}

const Roboflow = ({ onComplete }: { onComplete: (props: { screenshots: string[]; results: any }) => void }) => {
  const webcamRef = useRef<Webcam>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isScanning, setIsScanning] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFace, setIsFace] = useState<boolean>(false);
  const [isUnAuthenticated, setIsUnAuthenticated] = useState<boolean>(false);
  const [statusText, setStatusText] = useState<string>("");

  // Utility function to check if the device is mobile
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  // Set video constraints based on device type
  const videoConstraints = {
    facingMode: "user",
    width: isMobileDevice() ? 1280 : window.innerWidth,
    height: isMobileDevice() ? 720 : window.innerHeight,
  };

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const model = await window?.roboflow
      .auth({
        publishable_key: PUBLISHABLE_ROBOFLOW_API_KEY,
      })
      .load({
        model: MODEL,
        version: MODEL_VERSION,
        onMetadata: function () {
          console.log("Model loaded");
        },
      });
    console.log("Model ====", model);

    setIsLoading(false);
    setTimeout(() => {
      verifyFaces(model, []);
    }, 300);
  };

  const verifyFaces = async (model: any, screenshots: any[]) => {
    // Check data is available
    const validated = await detectFace(model);
    if (!validated) {
      setTimeout(() => {
        verifyFaces(model, screenshots);
      }, 100);
      return;
    }

    if (validated && screenshots.length < 3) {
      // Take screenshots
      screenshots.push(webcamRef.current?.getScreenshot());
      setTimeout(() => {
        verifyFaces(model, screenshots);
      }, 50);
      return;
    }

    console.log("screenshots", screenshots);

    clearCanvas();

    setIsLoading(true);

    // Compare faces.
    const promises = screenshots.map(sendVerifyRequest);
    const results = await Promise.all(promises);
    setIsLoading(false);

    // TODO: for production
    // const matched = results.filter((result) => result.isIdentical && result.confidence > 90);
    const matched = results.filter((result) => result.confidence > 1);
    console.log("results", results);

    // Complete auth if 4 photos of face are matched.
    setIsScanning(false);
    if (matched.length > 2) {
      onComplete({ screenshots, results });
    } else {
      setIsUnAuthenticated(true);
    }
  };

  const sendVerifyRequest = async (imageSrc: any) => {
    return await axios
      .post(
        "https://micro.nimblefi.com/service_azurevision/",
        // "https://1d37-95-216-76-224.ngrok-free.app/nimblefi/index.php/",
        {
          imageUrl1: imageSrc,
          imageUrl2:
            "https://media.licdn.com/dms/image/D5603AQETIsgquofnIA/profile-displayphoto-shrink_400_400/0/1697266557669?e=2147483647&v=beta&t=SbOD64dI-oPud59hqmVu_-vjewIxDqez8weM2v3CdUw",
        },
        {
          params: {
            apiKey: API_KEY,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.data);
  };

  const detectFace = async (model: any) => {
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current?.video?.readyState === 4
    ) {
      // Adjust size of webcam and canvas to size of video.
      const videoWidth = webcamRef.current.video.width;
      const videoHeight = webcamRef.current.video.height;
      adjustCanvas(videoWidth, videoHeight);

      // Detect face.
      const detections = await model.detect(webcamRef.current.video);
      const detection = detections[0] || {};

      // Check face detected
      if (detections.length < 1 || detection.class !== "Face") {
        setIsFace(false);
        return false;
      }

      // Draw outline of face.
      drawBoxes(detection);

      // Fit the face within the frame.
      setIsFace(true);

      const box = detection.bbox;

      // If the face too big.
      if (box.width > videoWidth - 100 || box.height > videoHeight - 100) {
        setStatusText("Back up");
        return false;
      }

      const boxX = box.x + box.width / 2;
      const boxY = box.y + box.height / 2;
      // If the face isn't in center.
      if (boxX < 50 || boxX > videoWidth - 50 || boxY < 50 || boxY > videoHeight - 50) {
        setStatusText("Center your face");
        return false;
      }

      // If the face too small.
      if (box.width < videoWidth / 10 || box.height < videoHeight / 10) {
        setStatusText("Move forward");
        return false;
      }

      const confidence = Math.trunc(detection.confidence * 100);
      if (confidence < 60) {
        setStatusText(`Low quality. ${confidence}%`);
        return false;
      }

      setStatusText("Good");

      return true;
    }
    return false;
  };

  const adjustCanvas = (w: number, h: number) => {
    if (!canvasRef.current) return;

    // canvasRef.current.width = w * window.devicePixelRatio;
    // canvasRef.current.height = h * window.devicePixelRatio;
    canvasRef.current.width = w;
    canvasRef.current.height = h;

    canvasRef.current.style.width = w + "px";
    canvasRef.current.style.height = h + "px";

    // const context = canvasRef.current.getContext("2d");
    // if (context) {
    //   context.scale(window.devicePixelRatio, window.devicePixelRatio);
    // }
  };

  const clearCanvas = (context?: any) => {
    const ctx = context || canvasRef.current?.getContext("2d");
    if (!ctx || !isLoading) return;

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  };

  const drawBoxes = (detection: any) => {
    const ctx = canvasRef.current?.getContext("2d");
    if (!canvasRef.current || !ctx || !webcamRef.current) return;

    clearCanvas(ctx);

    const faceBox = detection.bbox;

    const isMobile = isMobileDevice();
    //dimensions
    const fWidth = isMobile ? faceBox.width / 2 : faceBox.width;
    const x = isMobile ? (window.innerWidth - fWidth) / 2 : canvasRef.current.width - faceBox.x - faceBox.width / 2; // mirrored
    const y = faceBox.y - faceBox.height / 2;
    const w = isMobile ? faceBox.width / 2 : faceBox.width;
    const h = faceBox.height;

    // Draw box
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = detection.color;
    ctx.rect(x, y, w, h);
    ctx.stroke();

    // Shade box
    ctx.fillStyle = "black";
    ctx.globalAlpha = 0.2;
    ctx.fillRect(x, y, w, h);
    ctx.globalAlpha = 1.0;
    ctx.closePath();
  };

  return (
    <div className="relative w-screen h-screen overflow-hidden">
      <Webcam
        ref={webcamRef}
        audio={false}
        mirrored
        width={videoConstraints.width}
        height={videoConstraints.height}
        screenshotFormat="image/png"
        videoConstraints={videoConstraints}
        style={{
          transform: `scale(1)`, // Scale for zoom effect
          transformOrigin: "center", // Zoom from center
          width: "100%",
          height: "100%",
        }}
        className="w-full h-full object-cover"
      />
      <canvas ref={canvasRef} className="absolute mx-auto left-0 right-0 top-0" />
      <div
        className={`absolute top-0 bottom-0 left-0 right-0 text-white flex flex-col items-center justify-center ${
          isLoading || !isScanning ? "bg-black/75" : ""
        }`}
      >
        {isLoading ? (
          <img src={LoadingWhiteIcon} className="animate-spin w-10 h-10" />
        ) : (
          <>
            <p className="font-bold text-2xl text-center">
              {isScanning ? "Scanning..." : isUnAuthenticated ? "Authentication failed!" : "Scanning Completed!"}
            </p>
            <p className="text-sm text-center">
              {isScanning ? "Please hold Still" : isUnAuthenticated ? "Please try again." : "Have a nice day 👋"}
            </p>
            {!isUnAuthenticated ? (
              <p
                className={`mt-[60vh] py-2.5 px-3 rounded-full font-medium text-sm text-center ${
                  isScanning ? "text-black bg-white" : "text-white bg-green-700"
                }`}
              >
                {!isFace ? (
                  "Please Hold still."
                ) : (
                  <span className="flex items-center gap-1">
                    {statusText !== "Good" ? (
                      <img src={LoadingIcon} className="animate-spin" />
                    ) : (
                      <img src={isScanning ? CircleCheckGreenIcon : CircleCheckIcon} />
                    )}
                    {isScanning ? statusText : "Completed"}
                  </span>
                )}
              </p>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Roboflow;
