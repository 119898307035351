import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import FacialImage from "../../assets/images/facial-recognition.png";
import CameraIcon from "../../assets/images/camera.svg";

import Roboflow from "../../components/facial-recognition/Roboflow";
import MainLayout from "../../components/layout";
import FoundingSource from "../fundingSource";

const FacialRecognition = () => {
  const [searchParams] = useSearchParams();
  const [scanning, setScanning] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handleComplete = async ({ screenshots, results }: { screenshots: string[]; results: any }) => {
    const data = await axios.post(process.env.REACT_APP_API_URL + "auth/save-recognition", {
      email: searchParams.get("email"),
      screenshots,
      results,
    });
    console.log("data", data);
    setCompleted(true);
  };

  if (completed) {
    return (
      <MainLayout>
        <FoundingSource />
      </MainLayout>
    );
  }

  return (
    <>
      {!scanning ? (
        <>
          <div className="min-h-screen flex items-center justify-center">
            <div className="bg-white p-10 rounded-2xl shadow-lg max-w-xl w-full">
              <div className="mx-auto text-center">
                <h1 className="font-bold text-2xl mb-1">Facial Recognition</h1>
                <p className="text-sm text-neutral-500">
                  Please put your face as tight as you can within the dotted lines.
                </p>
              </div>
              <img src={FacialImage} alt="rate" className="mx-auto my-8 rounded-xl" />
              <center>
                <button
                  onClick={() => setScanning(true)}
                  className="flex items-center rounded-lg py-2.5 pl-4 pr-5 bg-[#016F3C] font-medium text-sm text-white"
                >
                  <img src={CameraIcon} className="mr-2" />
                  Finish Authenticating...
                </button>
              </center>
            </div>
          </div>
        </>
      ) : (
        <Roboflow onComplete={handleComplete} />
      )}
    </>
  );
};
export default FacialRecognition;
